@font-face {
  font-family: "DMSans Regular";
  src: url(../../media/fonts/DMSans-Regular.ttf);
}
@font-face {
  font-family: "DMSans Medium";
  src: url(../../media/fonts/DMSans-Medium.ttf);
}
@font-face {
  font-family: "DMSans Bold";
  src: url(../../media/fonts/DMSans-Bold.ttf);
}
/*!
 * Bootstrap Reboot v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

.unhide-x {
  overflow-x: unset;
}

body {
  padding: 0;
  margin: 0 auto;
  color: #68718b;
  font-family: "DMSans Regular";
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

h1 {
  font-size: 3.75rem;
  font-family: "DMSans Bold";
  letter-spacing: -0.03em;
  line-height: 1.1;
}
@media (max-width: 575.98px) {
  h1 {
    font-size: 2.5rem;
    line-height: 1.1;
  }
}

h2 {
  font-size: 3rem;
  font-family: "DMSans Bold";
  letter-spacing: -0.03em;
  line-height: 1.05;
  padding-bottom: 20px;
}
@media (max-width: 575.98px) {
  h2 {
    font-size: 2rem;
    line-height: 1;
  }
}

h3 {
  font-size: 2rem;
  font-family: "DMSans Bold";
  letter-spacing: -0.03em;
  line-height: 1.15;
}
@media (max-width: 575.98px) {
  h3 {
    font-size: 1.5rem;
    line-height: 1.1;
  }
}

h4 {
  font-size: 1.5rem;
  font-family: "DMSans Bold";
  letter-spacing: -0.03em;
  line-height: 1.05;
}
@media (max-width: 575.98px) {
  h4 {
    font-size: 1.25rem;
    line-height: 1.1;
  }
}

a {
  text-decoration: none;
}

.pattern,
.ornament {
  pointer-events: none;
}

.text-xl-bold {
  font-family: "DMSans Bold";
  font-size: 1.375rem;
  line-height: 1.1;
  letter-spacing: -0.03em;
}

.text-xl-medium {
  font-family: "DMSans Medium";
  font-size: 1.5rem;
  line-height: 1.1;
  letter-spacing: -0.03em;
}

.text-xl-regular {
  font-family: "DMSans Regular";
  font-size: 1.5rem;
  line-height: 1.1;
  letter-spacing: -0.03em;
}

.text-l-bold {
  font-family: "DMSans Bold";
  font-size: 1.25rem;
  line-height: 1.35;
}

.text-l-regular {
  font-family: "DMSans Regular";
  font-size: 1.25rem;
  line-height: 1.35;
}

.text-l-italic {
  font-family: "DMSans Regular";
  font-style: italic;
  font-size: 1.5rem;
  letter-spacing: 3px;
  line-height: 1.35;
}

.text-m-bold {
  font-family: "DMSans Bold";
  font-size: 1.125rem;
  line-height: 1.4;
}

.text-m-medium {
  font-family: "DMSans Medium";
  font-size: 1.125rem;
  line-height: 1.4;
}

.text-m-regular {
  font-family: "DMSans Regular";
  font-size: 1.125rem;
  line-height: 1.4;
}

.text-s-bold {
  font-family: "DMSans Bold";
  font-size: 0.875rem;
  line-height: 1.4;
}

.text-s-medium {
  font-family: "DMSans Medium";
  font-size: 0.875rem;
  line-height: 1.4;
}

.text-s-regular {
  font-family: "DMSans Regular";
  font-size: 0.875rem;
  line-height: 1.4;
}

.text-xs-bold {
  font-family: "DMSans Bold";
  font-size: 0.875rem;
  line-height: auto;
}

.text-xs-regular {
  font-family: "DMSans Regular";
  font-size: 0.875rem;
  line-height: auto;
}

@keyframes slideToRight {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideToLeft {
  from {
    transform: translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideToTop {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes continousUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
.home--logo {
  position: relative;
  padding: 30px 0px;
  opacity: 60%;
}
@media (max-width: 1199.98px) {
  .home--logo {
    padding: 20px 0px;
  }
}
.home__logo {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 42px;
  flex-wrap: wrap;
  text-align: center;
}
@media (max-width: 767.98px) {
  .home__logo {
    gap: 15px;
  }
  .home__logo img:last-child {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .home__logo {
    justify-content: center;
    align-items: center;
    grid-column-gap: 15px;
    grid-row-gap: 20px;
  }
}
@media (max-width: 375.98px) {
  .home__logo {
    gap: 0px;
  }
  .home__logo img {
    transform: scale(0.6);
    margin: 0px -20px;
  }
}
@media (max-width: 319.98px) {
  .home__logo img {
    margin: 0px -27px;
  }
}
.home__btn-3 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
}
.home--why-iwin {
  position: relative;
  width: 100%;
  padding: 80px 0px;
}
@media (max-width: 1199.98px) {
  .home--why-iwin {
    padding: 80px 20px;
  }
}
.home--why-iwin h2 {
  max-width: 576px;
  color: #1F404C;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.home__content-why {
  position: relative;
  height: 597px;
  display: flex;
  margin-top: 83px;
  gap: 30px;
}
@media (max-width: 1199.98px) {
  .home__content-why {
    margin-top: 23px;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 991.98px) {
  .home__content-why {
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .home__content-why {
    flex-direction: column;
    gap: 64px;
  }
}
.home--feature {
  position: relative;
  width: 100%;
  padding: 80px 0px;
}
.home--feature h2 {
  max-width: 733px;
  color: #1F404C;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 991.98px) {
  .home--feature {
    padding: 80px 20px;
  }
}
.home__feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 406px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .home__feature {
    flex-direction: column-reverse;
    gap: 50px;
    height: auto;
  }
}
@media (max-width: 991.98px) {
  .home__feature:nth-child(2) {
    flex-direction: column;
  }
}
.home__feature__detail {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45%;
  padding-top: 70px;
}
@media (max-width: 1199.98px) {
  .home__feature__detail {
    padding-top: 0px;
  }
}
@media (max-width: 991.98px) {
  .home__feature__detail {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.home__feature__detail .icon-large {
  margin-bottom: 24px;
}
.home__feature__detail__gap {
  position: relative;
  display: flex;
  gap: 24px;
  align-items: center;
}
@media (max-width: 991.98px) {
  .home__feature__detail__gap {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
}
.home__feature__detail__gap .icon-large {
  margin-bottom: 0px;
}
.home__feature__detail h2 {
  margin-top: 93px;
  color: #ffffff;
  max-width: 335px;
}
@media (max-width: 991.98px) {
  .home__feature__detail h2 {
    margin-top: 0px;
  }
}
.home__feature__detail h3 {
  color: #1F404C;
  text-transform: capitalize;
}
.home__feature__detail p.text-m-regular {
  color: #68718b;
  margin-top: 14px;
}
.home__feature__detail p.text-m-regular.text {
  color: #cdd0d8;
  margin-top: 14px;
  max-width: 382px;
}
.home__feature__detail__btn {
  margin-top: 48px;
  width: max-content;
}
.home__feature__illustration {
  position: relative;
  height: 100%;
  width: 45%;
}
@media (max-width: 991.98px) {
  .home__feature__illustration {
    width: 100%;
    height: 300px;
  }
}
.home__feature__illustration img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.home__container-feature {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 90px;
  padding-top: 64px;
}
@media (max-width: 1199.98px) {
  .home__container-feature {
    gap: 48px;
  }
}
.home--integrations {
  position: relative;
  width: 100%;
}
.home--integrations__bg {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(84deg, #4DA67F, #00637A);
  clip-path: polygon(0 220px, 100% 0, 100% calc(100% - 220px), 0% 100%);
}
@media (max-width: 991.98px) {
  .home--integrations__bg {
    clip-path: polygon(0 100px, 100% 0, 100% calc(100% - 100px), 0% 100%);
  }
}
@media (max-width: 575.98px) {
  .home--integrations__bg {
    clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0% 100%);
  }
}
.home--integrations .pattern {
  position: absolute;
  background-image: url("../../media/images/patterns-and-ornaments/home-v2-ornament-integrations.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 1760px;
  aspect-ratio: 1.68/1;
  top: 0;
  left: -209px;
}
.home__integrations {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 297px 0px;
}
@media (max-width: 1199.98px) {
  .home__integrations {
    padding-top: 190px 0px;
  }
}
.home__integrations__white {
  color: #ffffff;
  text-align: center;
}
.home__integrations__blue {
  color: #1F404C;
  text-align: center;
}
@media (max-width: 575.98px) {
  .home__integrations__blue {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.home__integrations__sosmed {
  position: relative;
  display: flex;
  margin-top: 64px;
}
.home__integrations__sosmed--v3 {
  position: relative;
  display: flex;
}
@media (max-width: 991.98px) {
  .home__integrations__sosmed--v3 {
    flex-direction: column-reverse;
  }
}
@media (max-width: 575.98px) {
  .home__integrations__sosmed--v3 {
    padding: 0px 20px;
  }
}
@media (max-width: 991.98px) {
  .home__integrations__sosmed {
    flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  .home__integrations__sosmed {
    padding: 0px 20px;
  }
}
.home__integrations__sosmed .gap {
  gap: 7px;
}
@media (max-width: 1199.98px) {
  .home__integrations__sosmed .gap {
    gap: 40px;
  }
}
@media (max-width: 991.98px) {
  .home__integrations__sosmed .gap-warp {
    flex-wrap: wrap;
    gap: 40px;
  }
}
.home--article {
  position: relative;
  width: 100%;
  padding: 5rem 0;
}
@media (max-width: 767.98px) {
  .home--article {
    padding: 4rem 0;
  }
}
@media (max-width: 575.98px) {
  .home--article {
    padding-inline: 1.25rem;
  }
}
.home__article {
  position: relative;
  display: flex;
  flex-direction: column;
}
.home__article__blue {
  color: #1F404C;
  text-align: center;
  max-width: 770px;
  align-self: center;
}
.home__article__sosmed {
  position: relative;
  display: flex;
  margin-top: 64px;
}
.home__article__sosmed--v3 {
  position: relative;
  display: flex;
}
@media (max-width: 991.98px) {
  .home__article__sosmed--v3 {
    flex-direction: column-reverse;
  }
}
@media (max-width: 575.98px) {
  .home__article__sosmed--v3 {
    padding: 0px 20px;
  }
}
@media (max-width: 991.98px) {
  .home__article__sosmed {
    flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  .home__article__sosmed {
    padding: 0px 20px;
  }
}
.home__article__sosmed .gap {
  gap: 7px;
}
@media (max-width: 1199.98px) {
  .home__article__sosmed .gap {
    gap: 40px;
  }
}
@media (max-width: 991.98px) {
  .home__article__sosmed .gap-warp {
    flex-wrap: wrap;
    gap: 40px;
  }
}
.home__article .home__content-why--article1 {
  position: relative;
  display: flex;
  margin-top: 64px;
  gap: 24px;
}
@media (max-width: 991.98px) {
  .home__article .home__content-why--article1 {
    flex-direction: column;
    height: 100%;
  }
}

.home__content-why__details {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  gap: 64px;
}

@media (max-width: 991.98px) {
  .home__content-why__details__detail {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
.home__content-why__details__detail h4 {
  max-width: 200px;
  color: #1F404C;
  margin-top: 24px;
  text-transform: capitalize;
}
.home__content-why__details__detail p.text-m-regular {
  max-width: 230px;
  color: #68718b;
  margin-top: 10px;
}
.home__content-why__details__detail--article {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 575.98px) {
  .home__content-why__details__detail--article {
    padding: 0px 20px;
  }
}

.home__content-why__details--article {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  gap: 24px;
}

.pattern {
  z-index: -1;
  position: absolute;
  background-size: contain;
  opacity: 50%;
}
.pattern--1 {
  background-image: url("../../media/images/patterns-and-ornaments/rocket.svg");
  background-position: center;
  top: 60%;
  width: 150px;
  left: 50%;
  aspect-ratio: 1/1;
}
.pattern--2 {
  background-image: url("../../media/images/patterns-and-ornaments/lightbulb.svg");
  background-position: right;
  width: 250px;
  top: 4vw;
  right: 0vw;
  aspect-ratio: 1/1.5;
}
.pattern--3 {
  background-image: url("../../media/images/patterns-and-ornaments/phone.svg");
  background-position: left;
  width: 200px;
  top: 12vw;
  left: 0vw;
  aspect-ratio: 1.3/1;
}

.btn--home-v2-why {
  width: 454px;
}
@media (max-width: 767.98px) {
  .btn--home-v2-why {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .btn--home-v2-integ {
    width: 100%;
  }
}
.btn--home-v2-feature-carousel {
  margin: auto;
}

.card--home-v2 {
  top: 15%;
  left: 5%;
}

.home-page-v2__heading {
  color: #1F404C;
  max-width: 650px;
}
@media (max-width: 991.98px) {
  .home-page-v2__heading {
    text-align: center;
  }
}

.home-v2 {
  /* Modal */
}
.home-v2 .home-v2-hero-section {
  position: relative;
}
.home-v2 .home-v2-hero-section .container {
  padding-top: 10.375rem;
  display: flex;
  flex-direction: column;
  gap: 10vw;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-hero-section .container {
    gap: 20vw;
  }
}
@media (max-width: 575.98px) {
  .home-v2 .home-v2-hero-section .container {
    gap: 30vw;
    padding-inline: 1.25rem;
    padding-top: 8.5rem;
  }
}
@media (max-width: 375.98px) {
  .home-v2 .home-v2-hero-section .container {
    gap: 147px;
  }
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 430px;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-hero-section .container .home-v2-hero-wrap {
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 48px;
  }
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-wrap .hero-ornament,
.home-v2 .home-v2-hero-section .container .home-v2-hero-wrap .hero-ornament-2 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-wrap .hero-ornament {
  background-image: url("../../media/images/patterns-and-ornaments/rocket.svg");
  width: 20%;
  aspect-ratio: 1/1;
  top: 2vw;
  left: 10vw;
  height: 200px;
  opacity: 1; /* Adjusted opacity to 100% */
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-wrap .hero-ornament-2 {
  background-image: url("../../media/images/patterns-and-ornaments/ornament-2.svg");
  width: 30%;
  aspect-ratio: 360.23/407.26;
  top: 2vw;
  right: 0;
  height: 200px;
  opacity: 1; /* Adjusted opacity to 100% */
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 70%;
  padding-left: 3%;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-hero-section .container .home-v2-hero-text-container {
    width: 100%;
    padding-left: 0%;
    align-items: center;
    text-align: center;
  }
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #ffffff;
  padding-bottom: 20px;
  width: 800px;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .plist {
  display: inline;
  line-height: 1.75rem;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .iplist {
  list-style-type: none;
  display: inline;
  color: #f47b3c;
  position: relative;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .iplist .item {
  position: absolute;
  display: inline;
  top: -2.5px;
  left: 5px;
  opacity: 0;
  transform: translateY(10px);
  text-align: left;
  width: 100%;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .iplist .ticker_1 {
  animation: appear 15s infinite ease;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .iplist .ticker_2 {
  animation: appear 15s 2.5s infinite ease;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .iplist .ticker_3 {
  animation: appear 15s 5s infinite ease;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .iplist .ticker_4 {
  animation: appear 15s 7.5s infinite ease;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .iplist .ticker_5 {
  animation: appear 15s 10s infinite ease;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .iplist .ticker_6 {
  animation: appear 15s 12.5s infinite ease;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  7% {
    opacity: 1;
    transform: translateY(0);
  }
  17% {
    opacity: 1;
    transform: translateY(0);
  }
  25% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text {
    width: 100%;
    align-items: center;
    text-align: center;
  }
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text__heading {
  max-width: 480px;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text__heading {
    max-width: 100%;
  }
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text__para {
  max-width: 800px;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text__para {
    max-width: 100%;
  }
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .home-v2-hero-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  top: 20px;
  gap: 24px;
}
@media (max-width: 375.98px) {
  .home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-text .home-v2-hero-btn-container {
    flex-direction: column;
    width: 100%;
  }
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-image-container {
  position: relative;
  width: 43vw;
  height: auto;
  top: -5vw;
  right: -10vw;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-image-container {
    width: 100%;
  }
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-image-container .home-v2-hero-image-graph {
  position: relative;
  left: 0;
  top: 46px;
  z-index: 2;
  width: 100%;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-image-container .home-v2-hero-image-graph {
    width: 100%;
  }
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-image-container .home-v2-hero-image-graph img {
  width: 84%;
  aspect-ratio: 2.19/1;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-image-container .home-v2-hero-image-graph-bg {
  position: relative;
  top: 0px;
  right: 145px;
  width: 100%;
}
.home-v2 .home-v2-hero-section .container .home-v2-hero-text-container .home-v2-hero-image-container .home-v2-hero-image-graph-bg img {
  width: 100%;
  aspect-ratio: 2578/2071;
}
.home-v2 .value {
  position: relative;
}
.home-v2 .value .container {
  padding: 3.125rem;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  align-items: center;
}
@media (max-width: 767.98px) {
  .home-v2 .value .container {
    padding: 4rem 0;
  }
}
@media (max-width: 575.98px) {
  .home-v2 .value .container {
    padding-inline: 1.25rem;
    gap: 3.5rem;
  }
}
.home-v2 .value .container .about-value-wrap {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.home-v2 .value .container .about-value-wrap .about-value-list {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 50px;
}
@media (max-width: 991.98px) {
  .home-v2 .value .container .about-value-wrap .about-value-list {
    flex-direction: column-reverse;
    text-align: center;
  }
  .home-v2 .value .container .about-value-wrap .about-value-list:nth-child(2) {
    flex-direction: column;
  }
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  width: 50%;
}
@media (max-width: 991.98px) {
  .home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-text-container {
    width: 100%;
    align-items: center;
  }
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-text-container .about-value-list-text {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media (max-width: 767.98px) {
  .home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-text-container .about-value-list-text .about-value-list__para {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container {
  position: relative;
  width: 50%;
}
@media (max-width: 991.98px) {
  .home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container {
    width: 100%;
  }
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-img--1 img, .home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-img--3 img {
  width: 100%;
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-img--2 img {
  width: 78.3%;
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-img--3 {
  position: relative;
}
@media (max-width: 991.98px) {
  .home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-img--3 {
    width: 100%;
  }
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-img--3 img {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 991.98px) {
  .home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-img--3 img {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img {
  position: absolute;
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img--1 {
  right: 0;
  bottom: 30px;
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img--1 img {
  width: 396px;
}
@media (max-width: 575.98px) {
  .home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img--1 img {
    width: 222px;
  }
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img--2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  right: 0;
  bottom: 20px;
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img--2 img {
  width: 274px;
}
@media (max-width: 575.98px) {
  .home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img--2 img {
    width: 154px;
  }
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img--3 {
  left: 0;
  bottom: 35px;
}
@media (max-width: 575.98px) {
  .home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img--3 {
    left: -2px;
  }
}
.home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img--3 img {
  width: 292px;
}
@media (max-width: 575.98px) {
  .home-v2 .value .container .about-value-wrap .about-value-list .about-value-list-image-container .about-value-list-float-img--3 img {
    width: 164px;
  }
}
.home-v2 .value .container .about-value-wrap .about-value-list__title {
  color: #1F404C;
}
.home-v2 .modal-dialog {
  max-width: 50%;
}
.home-v2 .modal-title {
  text-align: left;
  font-family: "DMSans bold";
  color: #1f404c;
}
.home-v2 .modal-body {
  text-align: left;
  font-family: "DMSans regular";
  color: #1f404c;
  padding: 2rem;
}
.home-v2 .modal-body .h3 {
  text-align: left;
  font-family: "DMSans regular";
  color: #1f404c;
}
.home-v2 .button .close {
  font-size: 1.125em;
  padding: 0px;
  font-family: "DMSans Bold";
  font-size: 1.125rem;
  line-height: 1.4;
  color: #f47b3c;
  text-align: left;
  border-radius: 0px/0px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.home-v2 .button .close:hover {
  color: #f47b3c;
}
.home-v2 .modal-body .content {
  font-size: 1.1rem;
  background: #ffffff;
}
.home-v2 .button {
  font-size: 1.125em;
  padding: 0px;
  font-family: "DMSans Bold";
  font-size: 1.125rem;
  line-height: 1.4;
  color: #f47b3c;
  text-align: left;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.home-v2 .button:hover {
  background: #ffffff;
}
@media screen and (max-width: 700px) {
  .home-v2 .box {
    width: 70%;
  }
  .home-v2 .popup {
    width: 70%;
  }
}
.home-v2 .home-v2-feature-section {
  position: relative;
  z-index: 1;
}
.home-v2 .home-v2-feature-section .home-v2-feature-ornament {
  position: absolute;
  background-image: url("../../media/images/patterns-and-ornaments/home-v2-ornament-feature.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 2581px;
  aspect-ratio: 2.92/1;
  top: 4rem;
  left: -18rem;
  z-index: -1;
}
.home-v2 .home-v2-feature-section .container {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 64px;
  align-items: flex-start;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-feature-section .container {
    align-items: center;
  }
}
@media (max-width: 575.98px) {
  .home-v2 .home-v2-feature-section .container {
    padding: 64px 20px;
    margin-bottom: 5rem;
  }
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop {
  display: flex;
  flex-direction: row;
  gap: 86px;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop {
    display: none;
  }
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop .home-v2-accordion-wrap {
  width: 50%;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop .home-v2-accordion-wrap .accordion .accordion-button {
  color: #1F404C;
  box-shadow: unset;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop .home-v2-accordion-wrap .accordion .accordion-button:focus {
  box-shadow: unset;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop .home-v2-accordion-wrap .accordion .accordion-button:not(.collapsed) {
  background-color: unset;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop .home-v2-accordion-wrap .accordion .accordion-button::after {
  background-image: url("../../media/images/icons/about-v1-slider.svg");
  background-size: cover;
  background-position: center;
  width: 40px;
  height: 40px;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop .home-v2-accordion-wrap .accordion .accordion-button .home-v2-accordion-header-wrap {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop .home-v2-accordion-wrap .accordion .accordion-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  padding: 24px 20px;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop .home-v2-accordion-wrap .accordion .accordion-body .home-v2-feature-accordion-text-wrap {
  color: #68718b;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop .home-v2-feature-image {
  width: 50%;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-desktop .home-v2-feature-image__img {
  box-shadow: 8px 24px 96px rgba(126, 138, 253, 0.08);
  width: 100%;
  aspect-ratio: 1.25/1;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet {
  align-self: center;
  width: 100%;
}
@media (min-width: 992px) {
  .home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet {
    display: none;
  }
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .carousel-indicators {
  margin-bottom: 0;
  bottom: -48px;
  gap: 12px;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .carousel-indicators button {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 12px; /* change width */
  height: 12px; /* change height */
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #a8dbfe;
  background-clip: padding-box;
  border: 0;
  transition: all 0.6s ease;
  border-radius: 100%;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .carousel-indicators button.active {
  background-color: #1F404C !important;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .home-v2-feature-carousel-item .home-v2-feature-carousel-image {
  width: fit-content;
  margin: auto;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .home-v2-feature-carousel-item .home-v2-feature-carousel-image img {
  width: 100%;
  aspect-ratio: 1.25/1;
  box-shadow: 5.27px 15.8px 63.18px rgba(126, 138, 253, 0.08);
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .home-v2-feature-carousel-item .home-v2-carousel-content-wrap {
  margin-top: 36px;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .home-v2-feature-carousel-item .home-v2-carousel-content-wrap .home-v2-carousel-icon {
  margin: auto;
  margin-bottom: 16px;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .home-v2-feature-carousel-item .home-v2-carousel-content-wrap .home-v2-carousel-text-container {
  text-align: center;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .home-v2-feature-carousel-item .home-v2-carousel-content-wrap .home-v2-carousel-text-container h4 {
  color: #1F404C;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .home-v2-feature-carousel-item .home-v2-carousel-content-wrap .home-v2-carousel-text-container .home-v2-feature-carousel-text-wrap {
  margin: auto;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-width: 335px;
}
.home-v2 .home-v2-feature-section .container .home-v2-feature-content-wrap-tablet .home-v2-feature-carousel-item .home-v2-carousel-content-wrap .home-v2-feature-carousel-button-wrap {
  margin-top: 24px;
}
.home-v2 .home-v2-integ-section {
  position: relative;
  z-index: 1;
}
.home-v2 .home-v2-integ-section .home-v2-integ-bg {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(84deg, #4DA67F, #00637A);
}
.home-v2 .home-v2-integ-section .ornament {
  position: absolute;
  background-image: url("../../media/images/patterns-and-ornaments/rocket.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 10vw;
  aspect-ratio: 1/1;
  bottom: 2vw;
  left: 10vw;
  opacity: 30%;
}
.home-v2 .home-v2-integ-section .ornament-2 {
  position: absolute;
  background-image: url("../../media/images/patterns-and-ornaments/lightbulb.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 20vw;
  aspect-ratio: 1/1;
  top: 2vw;
  right: 0;
  opacity: 30%;
}
.home-v2 .home-v2-integ-section .container {
  position: relative;
  padding: 9.375rem 0;
  display: flex;
  flex-direction: row;
  gap: 126px;
  align-items: center;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-integ-section .container {
    flex-direction: column;
    gap: 56px;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .home-v2 .home-v2-integ-section .container {
    padding: 7.375rem 0;
    padding-bottom: 8.125rem;
  }
}
@media (max-width: 575.98px) {
  .home-v2 .home-v2-integ-section .container {
    padding-inline: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-integ-section .container .home-v2-integ-button-wrap {
    display: none;
  }
}
@media (min-width: 992px) {
  .home-v2 .home-v2-integ-section .container .home-v2-integ-button-wrap-mobile {
    display: none;
  }
}
.home-v2 .home-v2-integ-section .container .home-v2-integ-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}
.home-v2 .home-v2-integ-section .container .home-v2-integ-left .home-v2-integ-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #ffffff;
}
.home-v2 .home-v2-integ-section .container .home-v2-integ-right .home-v2-integ-card-wrap {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-integ-section .container .home-v2-integ-right .home-v2-integ-card-wrap {
    flex-direction: column;
  }
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-integ-section .container .home-v2-integ-right .home-v2-integ-card-wrap .home-v2-integ-card-right {
    width: 50%;
  }
}
@media (max-width: 767.98px) {
  .home-v2 .home-v2-integ-section .container .home-v2-integ-right .home-v2-integ-card-wrap .home-v2-integ-card-right {
    width: 100%;
  }
}
.home-v2 .home-v2-integ-section .container .home-v2-integ-right .home-v2-integ-card-wrap .home-v2-integ-card-left {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (max-width: 991.98px) {
  .home-v2 .home-v2-integ-section .container .home-v2-integ-right .home-v2-integ-card-wrap .home-v2-integ-card-left {
    flex-direction: row;
  }
}
@media (max-width: 767.98px) {
  .home-v2 .home-v2-integ-section .container .home-v2-integ-right .home-v2-integ-card-wrap .home-v2-integ-card-left {
    flex-direction: column;
  }
}
.home-v2 .home-v2-article-section .container {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 66px;
  align-items: center;
  padding-top: 3%;
}
@media (max-width: 575.98px) {
  .home-v2 .home-v2-article-section .container {
    padding: 64px 20px;
  }
}
.home-v2 .home-v2-article-section .container .home-v2-article-header-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 575.98px) {
  .home-v2 .home-v2-article-section .container .home-v2-article-header-wrap {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .home-v2 .home-v2-article-section .container .home-v2-article-btn {
    display: none;
  }
}
@media (min-width: 768px) {
  .home-v2 .home-v2-article-section .container .home-v2-article-btn-mobile {
    display: none;
  }
}
.home-v2 .blog-successes-section {
  position: relative;
  z-index: 1;
}
.home-v2 .blog-successes-section #slider {
  margin: 50px auto;
  width: 800px;
  max-width: 100%;
  text-align: center;
}
.home-v2 .blog-successes-section #slider input[type=radio] {
  display: none;
}
.home-v2 .blog-successes-section #slider label {
  cursor: pointer;
  text-decoration: none;
}
.home-v2 .blog-successes-section #slides {
  padding: 5px;
  position: relative;
  z-index: 1;
}
.home-v2 .blog-successes-section #overflow {
  width: 100%;
  overflow: hidden;
}
.home-v2 .blog-successes-section #slide1:checked ~ #slides .inner {
  margin-left: 0;
}
.home-v2 .blog-successes-section #slide2:checked ~ #slides .inner {
  margin-left: -100%;
}
.home-v2 .blog-successes-section #slide3:checked ~ #slides .inner {
  margin-left: -200%;
}
.home-v2 .blog-successes-section #slides .inner {
  transition: margin-left 800ms cubic-bezier(0.77, 0, 0.175, 1);
  width: 400%;
  line-height: 0;
  height: 200px;
}
.home-v2 .blog-successes-section #slides .slide {
  width: 25%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  opacity: 1; /* Initially hide the slides */
  transition: opacity 0.8s ease; /* Add opacity transition */
}
.home-v2 .blog-successes-section #slides .slide.slide-from-right {
  opacity: 1; /* Show the slides with animation */
}
.home-v2 .blog-successes-section #controls {
  margin: -180px 0 0 0;
  width: 100%;
  height: 30px;
  z-index: 3;
  position: relative;
}
.home-v2 .blog-successes-section #controls label {
  transition: opacity 0.2s ease-out;
  display: none;
  width: 50px;
  height: 50px;
  opacity: 0.4;
}
.home-v2 .blog-successes-section #controls label:hover {
  opacity: 1;
}
.home-v2 .blog-successes-section #slide1:checked ~ #controls label:nth-child(2),
.home-v2 .blog-successes-section #slide2:checked ~ #controls label:nth-child(3),
.home-v2 .blog-successes-section #slide3:checked ~ #controls label:nth-child(1) {
  background: url(https://image.flaticon.com/icons/svg/130/130884.svg) no-repeat;
  float: right;
  margin: 0 -50px 0 0;
  display: block;
}
.home-v2 .blog-successes-section #slide1:checked ~ #controls label:nth-last-child(2),
.home-v2 .blog-successes-section #slide2:checked ~ #controls label:nth-last-child(3),
.home-v2 .blog-successes-section #slide3:checked ~ #controls label:nth-last-child(1) {
  background: url(https://image.flaticon.com/icons/svg/130/130882.svg) no-repeat;
  float: left;
  margin: 0 0 0 -50px;
  display: block;
}
.home-v2 .blog-successes-section #bullets {
  margin: 150px 0 0;
  text-align: center;
}
.home-v2 .blog-successes-section #bullets label {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ccc;
  margin: 0 10px;
}
.home-v2 .blog-successes-section #slide1:checked ~ #bullets label:nth-child(1),
.home-v2 .blog-successes-section #slide2:checked ~ #bullets label:nth-child(2),
.home-v2 .blog-successes-section #slide3:checked ~ #bullets label:nth-child(3) {
  background: #444;
}
@media screen and (max-width: 900px) {
  .home-v2 .blog-successes-section #slide1:checked ~ #controls label:nth-child(2),
  .home-v2 .blog-successes-section #slide2:checked ~ #controls label:nth-child(3),
  .home-v2 .blog-successes-section #slide3:checked ~ #controls label:nth-child(1) #slide1:checked ~ #controls label:nth-last-child(2),
  .home-v2 .blog-successes-section #slide2:checked ~ #controls label:nth-last-child(3),
  .home-v2 .blog-successes-section #slide3:checked ~ #controls label:nth-last-child(1) {
    margin: 0;
  }
  .home-v2 .blog-successes-section #slides {
    max-width: calc(100% - 140px);
    margin: 0 auto;
  }
}