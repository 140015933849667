$grid-breakpoints: (
  xs: 0,
  xs-1: 320px,
  // Custom Variable
  xs-2: 376px,
  xs-3: 426px,

  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxl-2: 1500px,
);

// Colors
$color-black: #03133d;
$color-grey: #68718b;
$color-light-grey: #cdd0d8;
$color-lighter-grey: #e6e8ec;
$color-white: #ffffff;

$color-dark-purple: #7c2bb8;
$color-purple: #F47B3C;
$color-light-purple: #c58df0;
$color-lighter-purple: #e0bdfb;
$color-extra-light-purple: #f7edfe;

$color-dark-blue: #1F404C;
$color-blue: #1F404C;
$color-light-blue: #1F404C;
$color-lighter-blue: #a8dbfe;
$color-extra-light-blue: #ebf7ff;

$color-dark-deep-blue: #1F404C;
$color-deep-blue: #1F404C;
$color-light-deep-blue: #6287e7;
$color-lighter-deep-blue: #c4d2f6;
$color-extra-light-deep-blue: #edf1fc;

$color-dark-green: #009e42;
$color-green: #4DA77F;
$color-light-green: #40e986;
$color-lighter-green: #9cf5c1;
$color-extra-light-green: #e2fced;

$color-dark-yellow: #d4912c;
$color-yellow: #0000;
$color-orange: #F47B3C;
$color-light-yellow: #feb38b;
$color-lighter-yellow: #ffe7b3;
$color-extra-light-yellow: #fff7e5;

$color-dark-red: #af2e1d;
$color-red: #e95c49;
$color-light-red: #f08d80;
$color-lighter-red: #f8cec8;
$color-extra-light-red: #fdefed;

// Gradients
$gradient-purple: linear-gradient(113deg, #6a36ff, $color-purple);
$gradient-orange: linear-gradient(113deg, $color-light-yellow, $color-orange);
$gradient-blue: linear-gradient(52deg, $color-blue, $color-dark-deep-blue);
$gradient-background: linear-gradient(84deg, #4DA67F, #00637A);

//Shadows
$shadow-sm: 8px 16px 52px rgba(#7e8afd, 0.08);
$shadow-md: 8px 24px 96px rgba(#7e8afd, 0.08);
$shadow-lg: 16px 32px 74px rgba(#7e8afd, 0.08);
