// Initialize first
@import "../init";

// Import files
@import "home";

// Pattern Settings
.pattern {
  z-index: -1;
  position: absolute;
  background-size: contain;
  opacity: 50%;

  &--1 {
    background-image: url("../../media/images/patterns-and-ornaments/rocket.svg");
    background-position: center;
    top: 60%;
    width: 150px;
    left: 50%;
    aspect-ratio: 1 / 1;
  }

  &--2 {
    background-image: url("../../media/images/patterns-and-ornaments/lightbulb.svg");
    background-position: right;
    width: 250px;
    top: 4vw;
    right: 0vw;
    aspect-ratio: 1 / 1.5;
  }

  &--3 {
    background-image: url("../../media/images/patterns-and-ornaments/phone.svg");
    background-position: left;
    width: 200px;
    top: 12vw;
    left: 0vw;
    aspect-ratio: 1.3 / 1;
  }
}

// Button Custom Styling
.btn {
  &--home-v2-why {
    width: 454px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &--home-v2-integ {
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &--home-v2-feature-carousel {
    margin: auto;
  }
}

// Card Custom Styling
.card {
  &--home-v2 {
    top: 15%;
    left: 5%;
  }
}

// Content Styling
.home-page-v2 {
  //   Set Universal Heading for Home V2
  &__heading {
    color: $color-dark-deep-blue;
    max-width: 650px;

    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
}

.home-v2 {
  // Section Hero Styling
  .home-v2-hero-section {
    position: relative;

    // Hero Content Styling
    .container {
      padding-top: toRem(166);
      display: flex;
      flex-direction: column;
      gap: 10vw;

      @include media-breakpoint-down(lg) {
        gap: 20vw;
        // padding: toRem(64) 0;
      }

      @include media-breakpoint-down(sm) {
        gap: 30vw;
        padding-inline: toRem(20);
        padding-top: toRem(136);
      }

      @include media-breakpoint-down(xs-2) {
        gap: 147px;
      }

      .home-v2-hero-wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        height: 430px;

        @include media-breakpoint-down(lg) {
          flex-direction: column;
          justify-content: center;
          align-items: left;
          gap: 48px;
        }

        // Hero Ornament Settings
        .hero-ornament,
        .hero-ornament-2 {
          position: relative;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .hero-ornament {
          background-image: url("../../media/images/patterns-and-ornaments/rocket.svg");
          width: 20%;
          aspect-ratio: 1 / 1;
          top: 2vw;
          left: 10vw;
          height: 200px;
          opacity: 1; /* Adjusted opacity to 100% */
        }

        .hero-ornament-2 {
          background-image: url("../../media/images/patterns-and-ornaments/ornament-2.svg");
          width: 30%;
          aspect-ratio: 360.23 / 407.26;
          top: 2vw;
          right: 0;
          height: 200px;
          opacity: 1; /* Adjusted opacity to 100% */
        }
      }

      // Hero Text Container
      .home-v2-hero-text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 70%;
        padding-left: 3%;

        @include media-breakpoint-down(lg) {
          width: 100%;
          padding-left: 0%;
          align-items: center;
          text-align: center;
        }

        //   Only Hero Text
        .home-v2-hero-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          color: $color-white;
          padding-bottom: 20px;
          width: 800px;

          .plist {
            display: inline;
            line-height: 1.75rem;
          }

          .iplist {
            list-style-type: none;
            display: inline;
            color: #f47b3c;
            position: relative;

            .item {
              position: absolute;
              display: inline;
              top: -2.5px;
              left: 5px;
              opacity: 0;
              transform: translateY(10px);
              text-align: left;
              width: 100%;
            }

            .ticker_1 {
              animation: appear 15s infinite ease;
            }

            .ticker_2 {
              animation: appear 15s 2.5s infinite ease;
            }

            .ticker_3 {
              animation: appear 15s 5s infinite ease;
            }

            .ticker_4 {
              animation: appear 15s 7.5s infinite ease;
            }

            .ticker_5 {
              animation: appear 15s 10s infinite ease;
            }
            .ticker_6 {
              animation: appear 15s 12.5s infinite ease;
            }
          }

          @keyframes appear {
            0% {
              opacity: 0;
            }
            7% {
              opacity: 1;
              transform: translateY(0);
            }
            17% {
              opacity: 1;
              transform: translateY(0);
            }
            25% {
              opacity: 0;
              transform: translateY(-10px);
            }
            100% {
              opacity: 0;
              transform: translateY(0);
            }
          }

          @include media-breakpoint-down(lg) {
            width: 100%;
            align-items: center;
            text-align: center;
          }

          &__heading {
            max-width: 480px;

            @include media-breakpoint-down(lg) {
              max-width: 100%;
            }
          }

          &__para {
            max-width: 800px;

            @include media-breakpoint-down(lg) {
              max-width: 100%;
            }
          }

          //   Only Hero Button
          .home-v2-hero-btn-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            top: 20px;
            gap: 24px;

            @include media-breakpoint-down(xs-2) {
              flex-direction: column;
              width: 100%;
            }
          }
        }

        // Hero Illustration Container
        .home-v2-hero-image-container {
          position: relative;
          width: 43vw;
          height: auto;
          top: -5vw;
          right: -10vw;

          @include media-breakpoint-down(lg) {
            width: 100%;
          }

          .home-v2-hero-image-graph {
            position: relative;
            left: 0;
            top: 46px;
            z-index: 2;
            width: 100%;

            @include media-breakpoint-down(lg) {
              width: 100%;
            }

            img {
              width: 84%;
              aspect-ratio: 2.19 / 1;
            }
          }

          .home-v2-hero-image-graph-bg {
            position: relative;
            top: 0px;
            right: 145px;
            width: 100%;

            img {
              width: 100%;
              aspect-ratio: 2578 / 2071;
            }
          }
        }
      }
    }
  }
  .value {
    position: relative;

    // Content Settings
    .container {
      padding: toRem(50);
      display: flex;
      flex-direction: column;
      gap: toRem(30);
      align-items: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
        gap: toRem(56);
      }

      .about-value-wrap {
        display: flex;
        flex-direction: column;
        gap: 100px;

        .about-value-list {
          display: flex;
          flex-direction: row;
          justify-content: left;
          gap: 50px;

          @include media-breakpoint-down(lg) {
            flex-direction: column-reverse;
            text-align: center;

            &:nth-child(2) {
              flex-direction: column;
            }
          }

          //   List Text Container
          .about-value-list-text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 32px;
            width: 50%;

            @include media-breakpoint-down(lg) {
              width: 100%;
              align-items: center;
            }

            .about-value-list-text {
              display: flex;
              flex-direction: column;
              gap: 50px;

              .about-value-list__para {
                @include media-breakpoint-down(md) {
                  @include text-shorten($line: 3);
                }
              }
            }
          }

          //   List Image Container
          .about-value-list-image-container {
            position: relative;
            width: 50%;

            @include media-breakpoint-down(lg) {
              width: 100%;
            }

            // Container Image
            .about-value-list-img {
              &--1,
              &--3 {
                img {
                  width: 100%;
                }
              }

              &--2 {
                img {
                  width: 78.3%;
                }
              }

              &--3 {
                position: relative;

                @include media-breakpoint-down(lg) {
                  width: 100%;
                }
                img {
                  position: absolute;
                  right: 0;
                  top: 0;

                  @include media-breakpoint-down(lg) {
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }

            // Float Image
            .about-value-list-float-img {
              position: absolute;

              &--1 {
                right: 0;
                bottom: 30px;
                img {
                  width: 396px;

                  @include media-breakpoint-down(sm) {
                    width: 222px;
                  }
                }
              }

              &--2 {
                display: flex;
                flex-direction: column;
                gap: 16px;
                right: 0;
                bottom: 20px;

                img {
                  width: 274px;

                  @include media-breakpoint-down(sm) {
                    width: 154px;
                  }
                }
              }

              &--3 {
                left: 0;
                bottom: 35px;

                @include media-breakpoint-down(sm) {
                  left: -2px;
                }

                img {
                  width: 292px;

                  @include media-breakpoint-down(sm) {
                    width: 164px;
                  }
                }
              }
            }
          }

          //   Style List Heading
          &__title {
            color: $color-dark-blue;
          }
        }
      }
    }
  }

  /* Modal */

  .modal-dialog {
    max-width: 50%;
  }

  .modal-title {
    text-align: left;
    font-family: "DMSans bold";
    color: #1f404c;
  }

  .modal-body {
    text-align: left;
    font-family: "DMSans regular";
    color: #1f404c;
    padding: 2rem;

    .h3 {
      text-align: left;
      font-family: "DMSans regular";
      color: #1f404c;
    }
  }

  .button {
    .close {
      font-size: 1.125em;
      padding: 0px;
      font-family: "DMSans Bold";
      font-size: toRem(18);
      line-height: 1.4;
      color: #f47b3c;
      text-align: left;
      border-radius: 0px/0px;
      cursor: pointer;
      transition: all 0.3s ease-out;
    }
    .close:hover {
      color: #f47b3c;
    }
  }

  .modal-body .content {
    font-size: 1.1rem;
    background: #ffffff;
  }

  .button {
    font-size: 1.125em;
    padding: 0px;
    font-family: "DMSans Bold";
    font-size: toRem(18);
    line-height: 1.4;
    color: #f47b3c;
    text-align: left;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .button:hover {
    background: #ffffff;
  }

  @media screen and (max-width: 700px) {
    .box {
      width: 70%;
    }
    .popup {
      width: 70%;
    }
  }
  // // Section Why Us Styling
  // .home-v2-why-section {
  //   .container {
  //     padding: 80px 0;
  //     display: flex;
  //     flex-direction: column;
  //     gap: 64px;

  //     @include media-breakpoint-down(lg) {
  //       padding: 64px 0;
  //       gap: 56px;
  //       align-items: center;
  //     }

  //     @include media-breakpoint-down(sm) {
  //       padding: 64px 20px;
  //     }

  //     // Why Us Header
  //     .home-v2-why-header-wrap {
  //       width: 100%;
  //       display: flex;
  //       flex-direction: row;
  //       justify-content: space-between;
  //       align-items: center;

  //       @include media-breakpoint-down(lg) {
  //         gap: 32px;
  //         justify-content: center;
  //       }
  //     }

  //     // Why Us Button
  //     .home-v2-why-btn {
  //       @include media-breakpoint-down(lg) {
  //         display: none;
  //       }
  //     }

  //     .home-v2-why-btn-tab {
  //       width: 100%;

  //       @include media-breakpoint-up(lg) {
  //         display: none;
  //       }
  //     }

  //     // Why Us List
  //     .home-v2-why-list-wrap {
  //       --bs-gutter-x: 30.3px;
  //       --bs-gutter-y: 0;

  //       @include media-breakpoint-down(lg) {
  //         --bs-gutter-y: 30.3px;
  //       }

  //       .home-v2-why-list-data {
  //         display: flex;
  //         flex-direction: column;
  //         align-items: flex-start;
  //         gap: 24px;

  //         @include media-breakpoint-down(lg) {
  //           align-items: center;
  //         }

  //         .home-v2-why-list-text {
  //           display: flex;
  //           flex-direction: column;
  //           gap: 12px;

  //           @include media-breakpoint-down(lg) {
  //             align-items: center;
  //             text-align: center;
  //           }

  //           &__title {
  //             color: $color-dark-blue;
  //             max-width: 159px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // Section Feature Styling
  .home-v2-feature-section {
    position: relative;
    z-index: 1;

    // Feature Ornament
    .home-v2-feature-ornament {
      position: absolute;
      background-image: url("../../media/images/patterns-and-ornaments/home-v2-ornament-feature.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 2581px;
      aspect-ratio: 2.92 / 1;
      top: 4rem;
      left: -18rem;
      z-index: -1;
    }

    // Feature Content
    .container {
      padding: 80px 0;
      display: flex;
      flex-direction: column;
      gap: 64px;
      align-items: flex-start;

      @include media-breakpoint-down(lg) {
        align-items: center;
      }

      @include media-breakpoint-down(sm) {
        padding: 64px 20px;
        margin-bottom: 5rem;
      }

      // Desktop Accordion
      .home-v2-feature-content-wrap-desktop {
        display: flex;
        flex-direction: row;
        gap: 86px;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(lg) {
          display: none;
        }

        // Accordion Wrapper
        .home-v2-accordion-wrap {
          width: 50%;

          .accordion {
            .accordion-button {
              color: $color-dark-blue;
              box-shadow: unset;

              &:focus {
                box-shadow: unset;
              }

              &:not(.collapsed) {
                background-color: unset;
              }

              &::after {
                background-image: url("../../media/images/icons/about-v1-slider.svg");
                background-size: cover;
                background-position: center;
                width: 40px;
                height: 40px;
              }

              .home-v2-accordion-header-wrap {
                display: flex;
                flex-direction: row;
                gap: 24px;
                align-items: center;
              }
            }

            .accordion-body {
              display: flex;
              flex-direction: column;
              gap: 24px;
              align-items: flex-start;
              padding: 24px 20px;

              .home-v2-feature-accordion-text-wrap {
                color: $color-grey;
              }
            }
          }
        }

        // Feature Image
        .home-v2-feature-image {
          width: 50%;

          &__img {
            box-shadow: $shadow-md;
            width: 100%;
            aspect-ratio: 1.25 / 1;
          }
        }
      }

      // Tablet Carousel
      .home-v2-feature-content-wrap-tablet {
        align-self: center;
        width: 100%;
        @include media-breakpoint-up(lg) {
          display: none;
        }

        // Custom Carousel Button
        .carousel-indicators {
          margin-bottom: 0;
          bottom: -48px;
          gap: 12px;

          button {
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 12px; /* change width */
            height: 12px; /* change height */
            padding: 0;
            margin-right: 3px;
            margin-left: 3px;
            text-indent: -999px;
            cursor: pointer;
            background-color: $color-lighter-blue;
            background-clip: padding-box;
            border: 0;
            transition: all 0.6s ease;
            border-radius: 100%; // /* add border-radius */

            &.active {
              background-color: $color-blue !important;
            }
          }
        }

        // Carousel Content Styling
        .home-v2-feature-carousel-item {
          // Carousel Image
          .home-v2-feature-carousel-image {
            width: fit-content;
            margin: auto;

            img {
              width: 100%;
              aspect-ratio: 1.25 / 1;
              box-shadow: 5.27px 15.8px 63.18px
                rgba($color: #7e8afd, $alpha: 0.08);
            }
          }

          // Carousel Content
          .home-v2-carousel-content-wrap {
            margin-top: 36px;

            .home-v2-carousel-icon {
              margin: auto;
              margin-bottom: 16px;
            }

            .home-v2-carousel-text-container {
              text-align: center;

              h4 {
                color: $color-dark-blue;
              }

              .home-v2-feature-carousel-text-wrap {
                margin: auto;
                margin-top: 8px;
                @include text-shorten($line: 3);
                max-width: 335px;
              }
            }

            .home-v2-feature-carousel-button-wrap {
              margin-top: 24px;
            }
          }
        }
      }
    }
  }

  // Section Integration Styling
  .home-v2-integ-section {
    position: relative;
    z-index: 1;

    // Bg Settings
    .home-v2-integ-bg {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: $gradient-background;

      @include media-breakpoint-down(md) {
      }
    }

    // Ornament Settings
    .ornament {
      position: absolute;
      background-image: url("../../media/images/patterns-and-ornaments/rocket.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 10vw;
      aspect-ratio: 1 / 1;
      bottom: 2vw;
      left: 10vw;
      opacity: 30%;
    }
    .ornament-2 {
      position: absolute;
      background-image: url("../../media/images/patterns-and-ornaments/lightbulb.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 20vw;
      aspect-ratio: 1 / 1;
      top: 2vw;
      right: 0;
      opacity: 30%;
    }

    // Content Settings
    .container {
      position: relative;
      padding: toRem(150) 0;
      display: flex;
      flex-direction: row;
      gap: 126px;
      align-items: center;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        gap: 56px;
        text-align: center;
      }

      @include media-breakpoint-down(md) {
        padding: toRem(118) 0;
        padding-bottom: toRem(130);
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
      }

      .home-v2-integ-button-wrap {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      .home-v2-integ-button-wrap-mobile {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      .home-v2-integ-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 48px;

        .home-v2-integ-text-wrap {
          display: flex;
          flex-direction: column;
          gap: 24px;
          color: $color-white;
        }
      }

      .home-v2-integ-right {
        .home-v2-integ-card-wrap {
          display: flex;
          flex-direction: row;
          gap: 24px;
          align-items: center;
          justify-content: center;

          @include media-breakpoint-down(lg) {
            flex-direction: column;
          }

          .home-v2-integ-card-right {
            @include media-breakpoint-down(lg) {
              width: 50%;
            }

            @include media-breakpoint-down(md) {
              width: 100%;
            }
          }

          .home-v2-integ-card-left {
            display: flex;
            flex-direction: column;
            gap: 24px;

            @include media-breakpoint-down(lg) {
              flex-direction: row;
            }

            @include media-breakpoint-down(md) {
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  // Section Article Styling
  .home-v2-article-section {
    .container {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      gap: 66px;
      align-items: center;
      padding-top: 3%;

      @include media-breakpoint-down(sm) {
        padding: 64px 20px;
      }

      .home-v2-article-header-wrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-down(sm) {
          justify-content: center;
        }
      }

      .home-v2-article-btn {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .home-v2-article-btn-mobile {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }

  // Section Customer Testimonial Styling
  .blog-successes-section {
    position: relative;
    z-index: 1;
    #slider {
      margin: 50px auto;
      width: 800px;
      max-width: 100%;
      text-align: center;
    }
    #slider input[type="radio"] {
      display: none;
    }
    #slider label {
      cursor: pointer;
      text-decoration: none;
    }
    #slides {
      padding: 5px;
      position: relative;
      z-index: 1;
    }
    #overflow {
      width: 100%;
      overflow: hidden;
    }
    #slide1:checked ~ #slides .inner {
      margin-left: 0;
    }
    #slide2:checked ~ #slides .inner {
      margin-left: -100%;
    }
    #slide3:checked ~ #slides .inner {
      margin-left: -200%;
    }
    #slides .inner {
      transition: margin-left 800ms cubic-bezier(0.77, 0, 0.175, 1);
      width: 400%;
      line-height: 0;
      height: 200px;
    }
    #slides .slide {
      width: 25%;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #fff;
      opacity: 1; /* Initially hide the slides */
      transition: opacity 0.8s ease; /* Add opacity transition */
    }

    #slides .slide.slide-from-right {
      opacity: 1; /* Show the slides with animation */
    }
    #slides .slide_1 {
    }
    #slides .slide_2 {
    }
    #slides .slide_3 {
    }
    #controls {
      margin: -180px 0 0 0;
      width: 100%;
      height: 30px;
      z-index: 3;
      position: relative;
    }
    #controls label {
      transition: opacity 0.2s ease-out;
      display: none;
      width: 50px;
      height: 50px;
      opacity: 0.4;
    }
    #controls label:hover {
      opacity: 1;
    }
    #slide1:checked ~ #controls label:nth-child(2),
    #slide2:checked ~ #controls label:nth-child(3),
    #slide3:checked ~ #controls label:nth-child(1) {
      background: url(https://image.flaticon.com/icons/svg/130/130884.svg)
        no-repeat;
      float: right;
      margin: 0 -50px 0 0;
      display: block;
    }
    #slide1:checked ~ #controls label:nth-last-child(2),
    #slide2:checked ~ #controls label:nth-last-child(3),
    #slide3:checked ~ #controls label:nth-last-child(1) {
      background: url(https://image.flaticon.com/icons/svg/130/130882.svg)
        no-repeat;
      float: left;
      margin: 0 0 0 -50px;
      display: block;
    }
    #bullets {
      margin: 150px 0 0;
      text-align: center;
    }

    #bullets label {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ccc;
      margin: 0 10px;
    }

    #slide1:checked ~ #bullets label:nth-child(1),
    #slide2:checked ~ #bullets label:nth-child(2),
    #slide3:checked ~ #bullets label:nth-child(3) {
      background: #444;
    }
    @media screen and (max-width: 900px) {
      #slide1:checked ~ #controls label:nth-child(2),
      #slide2:checked ~ #controls label:nth-child(3),
      #slide3:checked
        ~ #controls
        label:nth-child(1)
        #slide1:checked
        ~ #controls
        label:nth-last-child(2),
      #slide2:checked ~ #controls label:nth-last-child(3),
      #slide3:checked ~ #controls label:nth-last-child(1) {
        margin: 0;
      }
      #slides {
        max-width: calc(100% - 140px);
        margin: 0 auto;
      }
    }
  }
}
