// For global styling on all home pages

// Brand Section
// Size Section in Homepage
.home {
  &--logo {
    position: relative;
    padding: 30px 0px;
    opacity: 60%;

    @include media-breakpoint-down(xl) {
      padding: 20px 0px;
    }
  }

  &__logo {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 42px;
    flex-wrap: wrap;
    text-align: center;

    @include media-breakpoint-down(md) {
      gap: 15px;
      img:last-child {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      justify-content: center;
      align-items: center;
      grid-column-gap: 15px;
      grid-row-gap: 20px;
    }

    @include media-breakpoint-down(xs-2) {
      gap: 0px;
      img {
        transform: scale(0.6);
        margin: 0px -20px;
      }
    }

    @include media-breakpoint-down(xs-1) {
      img {
        margin: 0px -27px;
      }
    }
  }

  &__btn-3 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
  }

  &--why-iwin {
    position: relative;
    width: 100%;
    padding: 80px 0px;

    @include media-breakpoint-down(xl) {
      padding: 80px 20px;
    }

    h2 {
      max-width: 576px;
      color: $color-dark-deep-blue;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__content-why {
    position: relative;
    height: 597px;
    display: flex;
    margin-top: 83px;
    gap: 30px;

    @include media-breakpoint-down(xl) {
      margin-top: 23px;
      justify-content: center;
      align-items: center;
    }
    @include media-breakpoint-down(lg) {
      height: 100%;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 64px;
    }
  }

  // Section Feature in Home V1 and V3
  &--feature {
    position: relative;
    width: 100%;
    padding: 80px 0px;

    h2 {
      max-width: 733px;
      color: $color-dark-deep-blue;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-down(lg) {
      padding: 80px 20px;
    }
  }

  &__feature {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 406px;
    width: 100%;

    @include media-breakpoint-down(lg) {
      flex-direction: column-reverse;
      gap: 50px;
      height: auto;
    }

    &:nth-child(2) {
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }

    &__detail {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 45%;
      padding-top: 70px;

      @include media-breakpoint-down(xl) {
        padding-top: 0px;
      }

      @include media-breakpoint-down(lg) {
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .icon-large {
        margin-bottom: 24px;
      }

      &__gap {
        position: relative;
        display: flex;
        gap: 24px;
        align-items: center;

        @include media-breakpoint-down(lg) {
          width: 100%;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;
        }

        .icon-large {
          margin-bottom: 0px;
        }
      }

      h2 {
        margin-top: 93px;
        color: $color-white;
        max-width: 335px;

        @include media-breakpoint-down(lg) {
          margin-top: 0px;
        }
      }

      h3 {
        color: $color-dark-blue;
        text-transform: capitalize;
      }

      p.text-m-regular {
        color: $color-grey;
        margin-top: 14px;
      }

      p.text-m-regular.text {
        color: $color-light-grey;
        margin-top: 14px;
        max-width: 382px;
      }

      &__btn {
        margin-top: 48px;
        width: max-content;
      }
    }

    &__illustration {
      position: relative;
      height: 100%;
      width: 45%;

      @include media-breakpoint-down(lg) {
        width: 100%;
        height: 300px;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__container-feature {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 90px;
    padding-top: 64px;

    @include media-breakpoint-down(xl) {
      gap: 48px;
    }
  }

  // Section Integrations in Home V1 and V3
  &--integrations {
    position: relative;
    width: 100%;

    // Bg Settings
    &__bg {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: $gradient-background;
      clip-path: polygon(0 220px, 100% 0, 100% calc(100% - 220px), 0% 100%);

      @include media-breakpoint-down(lg) {
        clip-path: polygon(0 100px, 100% 0, 100% calc(100% - 100px), 0% 100%);
      }

      @include media-breakpoint-down(sm) {
        clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0% 100%);
      }
    }

    // Ornament Settings
    .pattern {
      position: absolute;
      background-image: url("../../media/images/patterns-and-ornaments/home-v2-ornament-integrations.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 1760px;
      aspect-ratio: 1.68 / 1;
      top: 0;
      left: -209px;
    }
  }

  &__integrations {
    position: relative;
    margin-left: auto;
    margin-right: auto;

    padding: 297px 0px;

    @include media-breakpoint-down(xl) {
      padding-top: 190px 0px;
    }

    &__white {
      color: $color-white;
      text-align: center;
    }

    &__blue {
      color: $color-dark-deep-blue;
      text-align: center;

      @include media-breakpoint-down(sm) {
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    &__sosmed {
      position: relative;
      display: flex;
      margin-top: 64px;

      &--v3 {
        position: relative;
        display: flex;

        @include media-breakpoint-down(lg) {
          flex-direction: column-reverse;
        }

        @include media-breakpoint-down(sm) {
          padding: 0px 20px;
        }
      }

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      @include media-breakpoint-down(sm) {
        padding: 0px 20px;
      }

      .gap {
        gap: 7px;

        @include media-breakpoint-down(xl) {
          gap: 40px;
        }
      }

      .gap-warp {
        @include media-breakpoint-down(lg) {
          flex-wrap: wrap;
          gap: 40px;
        }
      }
    }
  }

  // Section Articles in Home V1 and V3
  &--article {
    position: relative;
    width: 100%;
    padding: toRem(80) 0;

    @include media-breakpoint-down(md) {
      padding: toRem(64) 0;
    }

    @include media-breakpoint-down(sm) {
      padding-inline: toRem(20);
    }
  }

  &__article {
    position: relative;
    display: flex;
    flex-direction: column;

    &__blue {
      color: $color-dark-deep-blue;
      text-align: center;
      max-width: 770px;
      align-self: center;
    }

    &__sosmed {
      position: relative;
      display: flex;
      margin-top: 64px;

      &--v3 {
        position: relative;
        display: flex;

        @include media-breakpoint-down(lg) {
          flex-direction: column-reverse;
        }

        @include media-breakpoint-down(sm) {
          padding: 0px 20px;
        }
      }

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      @include media-breakpoint-down(sm) {
        padding: 0px 20px;
      }

      .gap {
        gap: 7px;

        @include media-breakpoint-down(xl) {
          gap: 40px;
        }
      }

      .gap-warp {
        @include media-breakpoint-down(lg) {
          flex-wrap: wrap;
          gap: 40px;
        }
      }
    }

    .home__content-why--article1 {
      position: relative;
      display: flex;
      margin-top: 64px;
      gap: 24px;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        height: 100%;
      }
    }
  }
}

// Section Why iwin in Home V1 and V3
.home__content-why__details {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  gap: 64px;
}

.home__content-why__details__detail {
  @include media-breakpoint-down(lg) {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  h4 {
    max-width: 200px;
    color: $color-dark-blue;
    margin-top: 24px;
    text-transform: capitalize;
  }

  p.text-m-regular {
    max-width: 230px;
    color: $color-grey;
    margin-top: 10px;
  }

  &--article {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding: 0px 20px;
    }
  }
}

.home__content-why__details--article {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  gap: 24px;
}
